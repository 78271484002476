<template>
  <el-form
    :model="froms"
    ref="froms"
    :rules="rules"
    label-width="50px"
    style="margin-left: 5%; width: 75%"
    class="answerDialog"
  >
    <div class="" >
      <ul class="bar">
        <p>开始答题</p>

        <li class="width100" v-show="explainList.length > 0">
          <b>使用说明：</b>
          <div class="flexFcAFs childWidth">
            <div
              style="padding: 0 2px"
              v-for="(item, index) in explainList"
              :key="index"
            >
              <div v-if="explainList.length == 1">
                <p>{{ item }}</p>
                <br />
              </div>
              <div v-else>{{ index + 1 }}. {{ item }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="moduleName flexJfAc" v-show="this.templateInfo.templateName">
      <span>模版：</span>
      <h3 style="textalign: left">{{ this.templateInfo.templateName }}</h3>
    </div>
    <div class="moduleName" v-show="this.questionList[0].moduleName">
      <div class="flexJfAc">
        <span>模块：</span>
        <h4 style="textalign: left">{{ this.questionList[0].moduleName }}</h4>
      </div>
      <div class="flexJfAc" >
        <span>说明：</span>
        <p>{{ this.questionList[0].moduleExplain }}</p>
      </div>
    </div>
    <div class="" v-for="(item, index) in this.questionGo" :key="index">
      <div class="flexJfAc" v-show="item.showTitle">
        <span>细分模块：</span>
        <h4 style="textalign: left">{{ item.subModuleName }}</h4>
      </div>
      <div class="flexJfAc" v-show="item.showTitle">
        <span>说明：</span>
        <p style="textalign: left">{{ item.subModuleExplain }}</p>
      </div>
      <!-- <p v-show="item.showTitle" class="subModuleExplain">{{ item.subModuleExplain }}</p> -->
      <el-form-item :label="index + 1 + '. ' + item.question">
        <div class="flexJfAc criterion" v-show="item.criterion">
          
          <div style="">评分标准：<span class="criterionText">{{ item.criterion }}</span></div>
        </div>

        <div class="">
          <el-radio-group
            :v-if="
              $parent.$parent.$data.question[titleTi][index].score !=
                undefined &&
              $parent.$parent.$data.question[titleTi][index].score != null &&
              $parent.$parent.$data.question[titleTi][index].score != ''
            "
            style="margin: 0 !important"
            v-model="$parent.$parent.$data.question[titleTi][index].score"
          >
            <el-radio
              v-for="(ite, index1) in item.options"
              :key="index1"
              :label="ite"
              @click.native.prevent="selectChange(ite, index)"
              >{{ ite }}分</el-radio
            >
          </el-radio-group>
          <!-- </div> -->
        </div>
      </el-form-item>
    </div>
    <el-form-item label="评语" v-if="isAbleSubmit">
      <el-input
        type="textarea"
        maxlength="200"
        show-word-limit
        v-model="froms.desc"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "answer",
  props: [
    "timu",
    "questionList",
    "titleTi",
    "isAbleSubmit",
    "question",
    "explainList",
    "templateInfo",
  ],
  data() {
    return {
      // timu: [
      //   "团队配置",
      //   "市场机会",
      //   "商业模式",
      //   "核心竞争力",
      //   "运营数据",
      //   "财务数据"
      // ],
      // titleTi: "团队配置",
      froms: { isArr: ["", "", "", ""], desc: null, recordItemList: [] },
      rules: {},
      questionGo: [],
      err: [],
    };
  },
  computed: {},
  watch: {
    questionList(val, r) {
      let valList = val;
      console.log("valList")
      let list = this.deWeight(JSON.parse(JSON.stringify(val)));
      console.log(list);
      for (var i = 0; i < valList.length; i++) {
        var obj = valList[i];
        var id = obj.id;
        console.log(obj.subModuleName);
        if (
          obj.subModuleName != "" ||
          obj.subModuleName != null ||
          obj.subModuleName != undefined
        ) {
          for (var j = 0; j < list.length; j++) {
            var aj = list[j];
            var n = aj.id;
            if (n == id) {
              obj.showTitle = true;
              break;
            } else {
              obj.showTitle = false;
            }
          }
        }
      }
      console.log(valList);
      this.questionGo = valList;
    },
    deep: true,
  },
  mounted(){
    this.getQuestionList(this.questionList)
  },
  methods: {
    getQuestionList(val) {
      let valList = val;
      console.log("valList")
      let list = this.deWeight(JSON.parse(JSON.stringify(val)));
      console.log(list);
      for (var i = 0; i < valList.length; i++) {
        var obj = valList[i];
        var id = obj.id;
        console.log(obj.subModuleName);
        if (
          obj.subModuleName != "" ||
          obj.subModuleName != null ||
          obj.subModuleName != undefined
        ) {
          for (var j = 0; j < list.length; j++) {
            var aj = list[j];
            var n = aj.id;
            if (n == id) {
              obj.showTitle = true;
              break;
            } else {
              obj.showTitle = false;
            }
          }
        }
      }
      console.log(valList);
      this.questionGo = valList;
    },
    radioChange(ite, index) {
      let score =
        this.$parent.$parent.$data.question[this.titleTi][index].score;
      console.log(ite + "------");
      console.log(
        this.$parent.$parent.$data.question[this.titleTi][index].score +
          "======------"
      );
      if (
        ite == this.$parent.$parent.$data.question[this.titleTi][index].score
      ) {
        console.log("yrs1");
        // this.$parent.$parent.$data.question[this.titleTi][index].score = "";
        // return false;
      }
      // this.$forceUpdate();
      ite == score ? (score = "") : (score = ite);
    },
    selectChange(ite, index) {
      console.log(this.$parent.$parent.$data.question[this.titleTi]);
      console.log(this.$parent.$parent.$data.question[this.titleTi][index]);
      console.log(this.$parent.$parent.$data.question[this.titleTi][index].score);
      ite == this.$parent.$parent.$data.question[this.titleTi][index].score
        ? (this.$parent.$parent.$data.question[this.titleTi][index].score = "")
        : (this.$parent.$parent.$data.question[this.titleTi][index].score =
            ite);
      // if (e.target.tagName === "INPUT") return;
      this.$forceUpdate();
    },
    deWeight(arr) {
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (
            arr[i].subModuleName != "" ||
            arr[i].subModuleName != null ||
            arr[i].subModuleName != undefined
          ) {
            if (arr[i].subModuleName == arr[j].subModuleName) {
              arr.splice(j, 1);
              //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
              j--;
            }
          }
        }
      }
      return arr;
    },
  },
  created() {}, 
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {
    // console.log(this.$parent.$parent.$data.question[this.titleTi][0]);
  },
  beforeDestroy() {},
  destroyed() {
    this.froms.desc = null;
  },
  activated() {},
  components: {},
};
</script>
<style scoped>
.answerDialog >>> .el-form-item__label {
  width: 100% !important;
  text-align: left !important;
  margin-left: 10px !important ;
  float: none;
}
.answerDialog >>> .el-form-item__content {
  width: 100% !important;
  margin: 0 0 0 30px !important;
  text-align: left !important;
}
.answerDialog >>> .el-radio {
  margin-right: 15px !important;
}
.showTitle {
  margin-top: 5px;
  font-weight: bold;
}
.moduleName {
  padding: 5px 0;
}
.subModuleExplain {
  margin: 2px 0;
}
</style>
<style lang="scss" scoped>
.bar {
  overflow: hidden;
  padding: 5px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      // min-width: 150px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 160px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
    // div {
    //   width: calc(100% - 160px);
    //   text-align: left;
    // }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    // p {
    //   width: calc(100% - 120px);
    //   float: right;
    //   span {
    //     display: inline;
    //     padding: 0 10px;
    //   }
    // }
    b {
      // min-width: 150px !important;
      text-align: right;
      align-self: baseline;
      // float: left;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
    // div {
    //   width: calc(100% - 160px);
    //   text-align: left;
    // }
  }
}
.criterion{
  .criterionText{
    // text-align: right;
    font-weight: bold; 
  }
}
</style>